import GlobalLayout from "components/page/GlobalLayout";
import PricingPage from "components/pricing/PricingPage";
import React from "react";
import { Helmet } from "react-helmet";

export default function AlarmMonitoringPricing() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Smart Commercial Alarm Monitoring System Pricing | Rhombus
        </title>
        <meta
          name="description"
          content="Get a free quote for Rhombus smart alarm monitoring system."
        />
      </Helmet>
      <PricingPage product="alarms" />
    </GlobalLayout>
  );
}
